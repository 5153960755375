<template>
    <div class="container">
        <section class="section">
            <div class="text-start">
                <h1 class="fw-bold mb-3">Farms</h1>
                <p class="mb-4">Stake token to earn.</p>
            </div>
            <div class="info-box d-flex w-100 bg-primary p-4 rounded-pill mb-3">
                <div class="d-flex flex-column">
                    <h3 class="text-white">$ {{numberWithCommas(totalValues)}}</h3>
                    <small class="text-white">Total values in Farm Pool</small>
                </div>
                <div class="d-flex flex-column">
                    <h3 class="text-white">$ {{numberWithCommas(totalLocked)}}</h3>
                    <small class="text-white">Total LP Locked</small>
                </div>
            </div>
            
            <ul class="fw-bold rounded-pill select-type d-flex my-5">
                <li class="py-3 rounded-pill" :class="{'bg-primary text-white':active==0}" @click="active=0">In progress</li>
                <li class="py-3 rounded-pill" :class="{'bg-primary text-white':active==1}" @click="active=1">Finished</li>
            </ul>
        </section>
        <section class="section staking-section">
            <div class="d-flex flex-wrap">
                <div class="bg-white d-flex flex-column rounded-lg shadow py-3 px-5 staking-list mb-4" v-for="(item,index) in stakingList" :key="index">
                    <div class="staking-head border-bottom py-4">
                        <h1 class="fw-bold mb-2">{{numberWithCommas(item.amount)}}</h1>
                        <p class="pb-4">남은 토큰 수량 ( {{item.name}} ) ≈ ${{numberWithCommas(item.dollar)}}</p>
                    </div>
                    <div class="staking-body">
                        <div class="d-flex aling-items-center py-3">
                            <b-img :src="item.coinIcon" rounded="circle" alt="Circle image"></b-img>
                            <span class="ms-3">{{item.name}}</span>
                        </div>
                        <div class="inner-box d-flex justify-content-around rounded-lg p-4 mb-4">
                            <div class="d-flex flex-column">
                                <h5 class="fw-bold mb-2 text-main">{{item.apr}}%</h5>
                                <p>APR</p>
                            </div>
                            <div class="d-flex flex-column">
                                <h5 class="fw-bold mb-2">{{item.name}}</h5>
                                <p>얻다</p>
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-between align-items-center mb-4">
                                <div class="d-flex flex-column text-start">
                                    <p>수확 ({{item.name}}) ≈ $0</p>
                                    <h5 class="fw-bold">{{item.earn}}</h5>
                                </div>
                                <b-button pill class="btn-outline-main long-btn">수취</b-button>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mb-4">
                                <div class="d-flex flex-column text-start">
                                    <p>저당됨 ({{item.name}})</p>
                                    <h5 class="fw-bold">{{item.mortgage}}</h5>
                                </div>
                                <b-button pill class="bg-primary long-btn border-0">Stake</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data(){
        return{
            active:0,
            totalValues:1637991,
            totalLocked:20502740,
            stakingList:[
                {
                    amount:485017087892,
                    name:"YOOSHI/ETH",
                    dollar:215345,
                    apr:30.45,
                    earn:0,
                    mortgage:0.00,
                    coinIcon:require("@/assets/img/color/eth.svg")
                },
                {
                    amount:485017087892,
                    name:"YOOSHI/ETH",
                    dollar:215345,
                    apr:30.45,
                    earn:0,
                    mortgage:0.00,
                    coinIcon:require("@/assets/img/color/eth.svg")
                },
                {
                    amount:485017087892,
                    name:"YOOSHI/ETH",
                    dollar:215345,
                    apr:30.45,
                    earn:0,
                    mortgage:0.00,
                    coinIcon:require("@/assets/img/color/eth.svg")
                },
            ]
        }
    },
    methods:{
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>

<style lang="scss" scoped>
.staking-section{
    margin-bottom: 100px;
    >div{
        gap: 2%;
    }
    .staking-list{
        width: 49%;
    }
    .staking-body{
        display: flex;
        flex-direction: column;
        .inner-box{
            background: linear-gradient(180deg, #fbfbfb, #eae9eb);
        }
    }
}
.info-box>div{
    width: 50%;
}
.long-btn{
    min-width: 120px;
}
@media screen and (max-width:768px) {
    .select-type{
        li{
            padding-bottom: 21px !important;
        }
    }
    .staking-section{
        .staking-list{
            font-size: 14px;
            padding: 20px 30px !important;
            width: 100%;
        }
    }
    .long-btn{
        min-width: 80px;
        font-size: 14px;
    }
}
</style>