<template>
    <div class="container">
        <section class="section">
            <div class="text-center">
                <h1 class="fw-bold mb-3">{{detail.name}}</h1>
                <p class="mb-5">{{detail.desc}}</p>
                <ul class="fw-bold rounded-pill select-type d-flex mb-5">
                    <li class="py-2 rounded-pill" :class="{'bg-primary text-white':active==0}" @click="active=0">Station 1</li>
                    <li class="py-2 rounded-pill" :class="{'bg-primary text-white':active==1}" @click="active=1">Station 2</li>
                </ul>
            </div>
        </section>
        <section class="mb-5">
            <div class="list-box bg-white p-4 shadow rounded-lg d-flex">
                <div class="p-3 py-4 d-flex flex-column justify-content-center rounded-lg">
                     <b-img :src="detail.img" rounded="circle" alt="Circle image" class="mx-auto mb-3"></b-img>
                     <h3 class="mb-4 fw-bold">{{detail.name}}</h3>
                     <h3 class="text-main mb-5 fw-bold">남은 : {{detail.remain}}</h3>
                     <div class="inner-box d-flex flex-column py-3 mb-3 rounded-lg">
                         <h5 class="fw-bold mb-2">활동 시간</h5>
                         <p>{{detail.time}}</p>
                     </div>
                     <b-button pill class="bg-primary text-white border-0 py-3 mb-3" @click="$store.commit('walletLoginPpFn',true) ">즉시 구매</b-button>
                     <span class="underline" @click="$store.commit('walletLoginPpFn',true) ">Share &amp; Earn $BNB</span>
                </div>
                <ul class="d-flex list-3-gap list-2-mobile flex-wrap">
                    <li v-for="(item,index) in detail.itemList" :key="index" class="list-3 d-flex flex-column p-4 rounded-lg position-relative" @click="detailPpOn(item)">
                        <b-badge variant="primary" class="badge btn-main position-absolute top-0 end-0"><span>{{item.hashrate}}x</span></b-badge>
                        <img :src="item.img" alt="img" class="w-100">
                        <span class="fw-bold">{{item.name}}</span>
                    </li>
                </ul>
            </div>
        </section>
        
        <b-modal v-model="openInfo" id="modal-center" centered :title="openDetail.name" hide-footer modal-class="pp-box" header-class="pp-header">
            <div class="d-flex p-4 pt-0">
                <div class="text-center left-box p-3">
                    <img :src="openDetail.img" alt="img" class="w-100">
                    <span class="fw-bold">{{openDetail.name}}</span>
                </div>
                <div class="right-box rounded-lg shadow bg-white py-3 px-4 d-flex flex-column">
                    <div class="d-flex align-items-center px-3 py-2 bg-cloudy rounded-lg mb-3">
                        <i class="far fa-coins"></i>
                        <div class="d-flex flex-column ps-3">
                            <h5 class="fw-bold">{{openDetail.hashrate}}x</h5>
                            <p>마이닝 해시레이트</p>
                        </div>
                    </div>
                    
                    <div class="d-flex align-items-center px-3 py-2 bg-cloudy rounded-lg mb-3">
                        <i class="far fa-sync-alt"></i>
                        <div class="d-flex flex-column ps-3">
                            <h5 class="fw-bold">{{openDetail.volume}}</h5>
                            <p>유통수량</p>
                        </div>
                    </div>
                    
                    <div class="d-flex align-items-center px-3 py-2 bg-cloudy rounded-lg mb-3">
                        <i class="far fa-parachute-box"></i>
                        <div class="d-flex flex-column ps-3">
                            <h5 class="fw-bold">{{openDetail.totalSupply}}</h5>
                            <p>총 공급</p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>계약주소</span>
                        <div class="w-50 text-truncate">{{openDetail.address}}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>자산 프로토콜</span>
                        <span>{{openDetail.protocol}}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>자산 퍼스릭 체인</span>
                        <span>{{openDetail.chain}}</span>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data(){
        return{
            active:1,
            openInfo:false,
            openDetail:{
                name:'',
                img:'',
                hashrate:0, 
                volume:0,
                totalSupply:0,
                address:"",
                protocol:"",
                chain:""
            },
            detail:{
                img:"https://via.placeholder.com/170x170?text=NFT+MARKET+BANNER",
                name:"DNAxCAT NFT",
                desc:"Praying for the meow goddess can get a mysterious gift from the meow goddess. According to legend, these gems can summon legendary meow warriors! This is the key to defeating the evil forces on the Aydin land and restoring peace!",
                remain:0,
                time:'UTC+9 Aug 10, 21:00 ~ Aug 17, 21:00',
                itemList:[
                    {
                        img:"https://via.placeholder.com/170x170?text=NFT+MARKET+BANNER",
                        name:"Cat goddess Emerald",
                        hashrate:100, 
                        volume:2340,
                        totalSupply:2340,
                        address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                        protocol:"ERC721",
                        chain:"BSC"
                    },
                    {
                        img:"https://via.placeholder.com/170x170?text=NFT+MARKET+BANNER",
                        name:"Cat goddess Light Sapphire",
                        hashrate:'120', 
                        volume:1728,
                        totalSupply:1728,
                        address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                        protocol:"ERC721",
                        chain:"BSC"
                    },
                    {
                        img:"https://via.placeholder.com/170x170?text=NFT+MARKET+BANNER",
                        name:"Cat goddess dark sapphire",
                        hashrate:'100', 
                        volume:2340,
                        totalSupply:2340,
                        address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                        protocol:"ERC721",
                        chain:"BSC"
                    },
                    {
                        img:"https://via.placeholder.com/170x170?text=NFT+MARKET+BANNER",
                        name:"Cat goddess purple gem",
                        hashrate:'100', 
                        volume:2340,
                        totalSupply:2340,
                        address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                        protocol:"ERC721",
                        chain:"BSC"
                    },
                    {
                        img:"https://via.placeholder.com/170x170?text=NFT+MARKET+BANNER",
                        name:"Cat goddess Topaz",
                        hashrate:'100', 
                        volume:2340,
                        totalSupply:2340,
                        address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                        protocol:"ERC721",
                        chain:"BSC"
                    },
                    {
                        img:"https://via.placeholder.com/170x170?text=NFT+MARKET+BANNER",
                        name:"Cat goddess Ruby",
                        hashrate:'100', 
                        volume:2340,
                        totalSupply:2340,
                        address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                        protocol:"ERC721",
                        chain:"BSC"
                    }
                ]
            }
        }
},
    methods:{
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        detailPpOn(item){
            this.openInfo=!this.openInfo;
            this.openDetail = item;
        }
    }
}
</script>

<style lang="scss" scoped>
.select-type>li{
    padding-bottom: 11px !important;

}
.list-box{
    gap: 20px;
    >div{
        width: 40%;
        background: linear-gradient(180deg, #fff2fc, #ede0ff);
        img{
            width: 72px;
            height: 72px;
        }
        .inner-box{
            background-color: rgba(255, 255, 255, 0.6)
        }
    }
    >ul{
        width: 60%;
        >li{
            background: linear-gradient(180deg, #fbfbfb, #eae9eb);
            .badge{
                transform: translate(-50%, 80%);
            }
            img{
                transition: transform .3s ease;
            }
            &:hover img{
                transform: scale(1.05);
            }
        }
    }
}
.left-box{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 24px;
    padding-left: 0 !important;
}
.right-box{
    width: 60%;
    justify-content: space-around;
}
@media screen and (max-width:768px){
    .list-box{
        flex-wrap: wrap;
        gap: 20px;
        >div{
            width: 100%;
            font-size: 14px;
        }
        >ul{
            width: 100%;
        }
    }
    
    .left-box{
        width: 100%;
    }
}
</style>
<style lang="scss">

.pp-box .modal-content{
    min-width: 600px;
    border: 0;
    border-radius: 16px;
    font-size: 14px;
    .pp-header{
        border-bottom: 0;
    }
    .modal-title{
        margin: 0 auto;
    }
    p{
        font-size: 12px;
    }
    .close{
        font-weight: bold;
        background-color: transparent;
        border: 0;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
        font-size: 1.8em;
        color: #fff;
        &::before{
            content: "\f057";
            color: #333;
        }
    }
}
</style>