<template>
    <div class="container">
        <div class="info-box d-flex w-100 bg-primary p-4 rounded-lg rounded-pill">
            <div class="d-flex flex-column">
                <h3 class="text-white">{{numberWithCommas(supply)}}</h3>
                <small class="text-white">총 공급</small>
            </div>
            
            <div class="d-flex flex-column">
                <h3 class="text-white">{{numberWithCommas(circulation)}}</h3>
                <small class="text-white">유통 수량</small>
            </div>
            
            <div class="d-flex flex-column">
                <h3 class="text-white">{{numberWithCommas(incineration)}}</h3>
                <small class="text-white">소각된 수</small>
            </div>
        </div>

        <section class="mb-5">
            <div class="list-box p-4 d-flex">
                <ul class="d-flex list-4-gap list-2-mobile flex-wrap">
                    <li v-for="(item,index) in itemList" :key="index" class="d-flex flex-column p-4 rounded-lg position-relative list-4" @click="detailPpOn(item)">
                        <b-badge variant="primary" class="badge btn-main position-absolute top-0 end-0"><span>{{item.hashrate}}x</span></b-badge>
                        <img :src="item.img" alt="img" class="w-100">
                        <span class="fw-bold">{{item.name}}</span>
                    </li>
                </ul>
            </div>
        </section>
        
        <b-modal v-model="openInfo" id="modal-center" centered :title="openDetail.name" hide-footer modal-class="pp-box" header-class="pp-header">
            <div class="d-flex pt-0">
                <div class="text-center left-box">
                    <img :src="openDetail.img" alt="img" class="w-100">
                    <span class="fw-bold">{{openDetail.name}}</span>
                </div>
                <div class="right-box rounded-lg shadow bg-white py-3 px-4 d-flex flex-column">
                    <div class="d-flex align-items-center px-3 py-2 bg-cloudy rounded-lg mb-3">
                        <i class="far fa-coins"></i>
                        <div class="d-flex flex-column ps-3">
                            <h5 class="fw-bold">{{openDetail.hashrate}}x</h5>
                            <p>마이닝 해시레이트</p>
                        </div>
                    </div>
                    
                    <div class="d-flex align-items-center px-3 py-2 bg-cloudy rounded-lg mb-3">
                        <i class="far fa-sync-alt"></i>
                        <div class="d-flex flex-column ps-3">
                            <h5 class="fw-bold">{{openDetail.volume}}</h5>
                            <p>유통수량</p>
                        </div>
                    </div>
                    
                    <div class="d-flex align-items-center px-3 py-2 bg-cloudy rounded-lg mb-3">
                        <i class="far fa-parachute-box"></i>
                        <div class="d-flex flex-column ps-3">
                            <h5 class="fw-bold">{{openDetail.totalSupply}}</h5>
                            <p>총 공급</p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>계약주소</span>
                        <div class="w-50 text-truncate">{{openDetail.address}}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>자산 프로토콜</span>
                        <span>{{openDetail.protocol}}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>자산 퍼스릭 체인</span>
                        <span>{{openDetail.chain}}</span>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data(){
        return{
            supply: 34516,
            circulation: 21451,
            incineration: 1435,

            openInfo:false,
            openDetail:{
                name:'',
                img:'',
                hashrate:0, 
                volume:0,
                totalSupply:0,
                address:"",
                protocol:"",
                chain:""
            },
            itemList:[
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess Emerald",
                    hashrate:100, 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess Light Sapphire",
                    hashrate:'120', 
                    volume:1728,
                    totalSupply:1728,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess dark sapphire",
                    hashrate:'100', 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess purple gem",
                    hashrate:'100', 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess Topaz",
                    hashrate:'100', 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess Ruby",
                    hashrate:'100', 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess Emerald",
                    hashrate:100, 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess Light Sapphire",
                    hashrate:'120', 
                    volume:1728,
                    totalSupply:1728,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess dark sapphire",
                    hashrate:'100', 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess purple gem",
                    hashrate:'100', 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                },
                {
                    img:require('@/assets/img/banner2.jpg'),
                    name:"Cat goddess Topaz",
                    hashrate:'100', 
                    volume:2340,
                    totalSupply:2340,
                    address:"0x57e6EE4a2d1804Fa49FE007674f096f748AC3c40",
                    protocol:"ERC721",
                    chain:"BSC"
                }
            ]
        }
    },
    methods:{
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        detailPpOn(item){
            this.openInfo=!this.openInfo;
            this.openDetail = item;
        }
    }
}
</script>

<style lang="scss" scoped>
.list-box{
    gap: 20px;
    >ul{
        width: 100%;
        >li{
            background: linear-gradient(180deg, #fbfbfb, #eae9eb);
            .badge{
                transform: translate(-50%, 80%);
                position: relative;
                z-index: 1;
            }
            img{
                border-radius: 24px;
                transition: transform .3s ease;
                margin-bottom: 5px;
            }
            &:hover img{
                transform: scale(1.05);
            }
        }
    }
}

.left-box{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 24px;
    padding-right: 30px !important;
}
.right-box{
    width: 60%;
    justify-content: space-around;
}

@media screen and (max-width: 960px){
    .list-4{
        width: 32%;
        text-align: center;
    }
    .list-box{
        padding: 0 !important;
        padding-top: 25px !important;
    }
}
</style>
<style lang="scss">
.modal-body>div{
    padding: 25px;
}
@media screen and (max-width:768px) {
    .modal-body{
        padding-top: 0 !important;
        >div{
        padding: 0;
        }
    }
    .left-box{
        display: none !important;
    }
    .right-box{
        width:100% !important;
    }   
}
</style>