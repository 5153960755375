<template>
    <div class="pb-5">
        <div class="top-wrapper" style="background-image: url('https://d1vyp5kjqdxn2l.cloudfront.net/cdnstatic/img/4892950f3a0c81739e2e19b45d78541a.png');">
        <div class="pc-inner-widget">
            <div class="crumbs-box">
                <div class="crumb-view crumbs-detail">
                    <div class="crumb-item"><router-link to="/home" class="">홈페이지</router-link><span class="crumb-space">&gt;</span></div>
                    <div class="crumb-item"><router-link to="/games" class="router-link-active">Games</router-link><span class="crumb-space">&gt;</span></div>
                    <div class="crumb-item"><span>DNAxCAT</span></div>
                </div>
            </div>
            <div class="top-box">
                <div class="left-view">
                    <VueSlickCarousel class="slider-for"
                     ref="c1" :asNavFor="c2" v-bind="settings">
                        <div v-for="(item,index) in banner" :key="index">
                            <router-link :to="item.link" class="touchable-link">
                                <img :src="item.img" alt="">
                            </router-link>
                        </div>
                    </VueSlickCarousel>
                    <VueSlickCarousel class="slider-nav mt-3"
                     ref="c2" :asNavFor="c1" v-bind="settings2">
                        <div v-for="(item,index) in banner" :key="index">
                            <img :src="item.img" alt="">
                        </div>
                    </VueSlickCarousel>

                </div>
                <div class="right-view">
                    <div class="game-title">DNAxCAT</div>
                    <div class="line"></div>
                    <div class="game-info"><span class="info-left">Category</span><span class="info-right">Collection,Education</span></div>
                    <div class="game-info"><span class="info-left">State</span><span class="info-right">Game Online</span></div>
                    <div class="game-info"><span class="info-left">Language</span><span class="info-right">English</span></div>
                    <div class="game-info"><span class="info-left">Website</span><a target="_blank" href="https://dnaxcat.io" class="info-right website">https://dnaxcat.io</a></div>
                    <div class="game-info">
                        <span class="info-left">Community</span>
                        <div class="icon-right icon-list">
                            <a target="_blank" href="https://poocoin.app/tokens/0x5b1baec64af6dc54e6e04349315919129a6d3c23" class="icon"><img src="@/assets/img/icon/chart_icon.svg" alt="" /></a>
                            <a target="_blank" href="https://t.me/DNAxCAT_official" class="icon"><img src="@/assets/img/icon/telegram_icon.svg" alt="" /></a>
                            <a target="_blank" href="https://twitter.com/dnaxcat_game" class="icon"><img src="@/assets/img/icon/twitter_icon.svg" alt="" /></a>
                            <a target="_blank" href="https://medium.com/@DNAxCAT_GAME" class="icon"><img src="@/assets/img/icon/medium_icon.svg" alt="" /></a>
                            <!---->
                            <a target="_blank" href="https://discord.com/invite/YooShiGamePad" class="icon"><img src="@/assets/img/icon/discord_icon.svg" alt="" /></a>
                        </div>
                    </div>
                    <div class="item-buttons">
                        <a target="_blank" href="https://dnaxcat.io" class="btn btn-main"><span>Play Now</span></a>
                        <a
                        
                            target="_blank"
                            href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&amp;outputCurrency=0x5b1baec64af6dc54e6e04349315919129a6d3c23"
                            class="btn btn-main outline"
                        >
                            <span>Buy $DXCT</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container pt-3">
        <section class="section mt-4">
            <h3 class="small-title fw-bold mb-3 d-flex justify-content-between">
                Earn
            </h3>
            <ul class="d-flex flex-wrap pt-3 list-3-gap">
                <li class="shadow d-flex flex-column p-3 list-3 rounded-lg bg-white">
                    <div class="d-flex flex-column position-relative">
                        <img src="https://via.placeholder.com/352x177?text=NFT+MARKET+BANNER" alt="" class="rounded">
                        <h5 class="pt-4 pb-1 fw-bold text-start">Play to Earn</h5>
                        <p class="pb-4 fw-bold text-start">Earn Fish Cookie ($SFC)</p>
                    </div>
                    <div class="d-flex flex-column justify-content-around">
                        <b-button class="bg-primary border-0 py-2" pill><span>Get Started</span></b-button>
                    </div>
                </li>
                <li class="shadow d-flex flex-column p-3 list-3 rounded-lg bg-white">
                    <div class="d-flex flex-column position-relative">
                        <img src="https://via.placeholder.com/352x177?text=NFT+MARKET+BANNER" alt="" class="rounded">
                        <h5 class="pt-4 pb-1 fw-bold text-start">DXCT/BUSD Farms 2.0</h5>
                        <div class="d-flex justify-content-between">
                            <p class="pb-4 fw-bold text-start">Pool Amount</p>
                            <span class="text-main">2,451,751 $DXCT</span>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-around">
                        <b-button class="bg-primary border-0 py-2" pill><span>Mining</span></b-button>
                    </div>
                </li>
                <li class="shadow d-flex flex-column p-3 list-3 rounded-lg bg-white">
                    <div class="d-flex flex-column position-relative">
                        <img src="https://via.placeholder.com/352x177?text=NFT+MARKET+BANNER" alt="" class="rounded">
                        <h5 class="pt-4 pb-1 fw-bold text-start">DNAxCAT Genesis Mining Pool</h5>
                        <div class="d-flex justify-content-between">
                            <p class="pb-4 fw-bold text-start">Pool Amount</p>
                            <span class="text-main">0 $DXCT</span>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-around">
                        <b-button class="bg-primary border-0 py-2" pill><span>Mining</span></b-button>
                    </div>
                </li>
            </ul>
        </section>

        <section class="section mt-4">
            <h3 class="small-title fw-bold mb-3 d-flex justify-content-between">
                Buy Cat <b-button pill variant="outline-primary">View More</b-button>
            </h3>
            <ul class="d-flex gap-3 buy_list">
                <li v-for="(item,index) in catList" :key="index" class="shadow d-flex flex-column p-3 bg-white">
                    <div class="img_box rounded overflow-hidden d-flex flex-column">
                        <img :src="item.img" alt="" class="rounded">
                        <b class="py-2">{{item.name}}</b>
                    </div>
                    <div class="d-flex flex-column justify-content-around mt-2">
                        <div class="d-flex justify-content-between align-items-center">
                            Summon Times
                            <span class="fw-bold">{{item.summon}}</span>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            Price
                            <span class="fw-bold">{{item.price}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </section>

        <section class="section mt-4">
            <h3 class="small-title fw-bold mb-3 d-flex justify-content-between">
                Games
            </h3>
            <ul class="d-flex gap-3 game_list overflow-hidden">
                <li v-for="(item,index) in games" :key="index">
                    <div class="bg_img" :style="`background-image:url(${item.img})`"></div>
                </li>
            </ul>
        </section>
    </div>

    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    components: { VueSlickCarousel },
    mounted(){
        this.c1 = this.$refs.c1;
        this.c2 = this.$refs.c2;
    },
    data(){
        return{
            c1:undefined,
            c2:undefined,
            settings:{
                "arrows": true,
                "dots": false,
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "autoplay": true,
                "speed": 400,
                "autoplaySpeed": 4000,
                "cssEase": "linear",
                "focusOnSelect": true

            },
            settings2:{
                "arrows": false,
                "dots": false,
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "centerMode": true,
                "focusOnSelect": true
            },
            banner:[
                {
                    link:"",
                    img:"https://via.placeholder.com/780x347?text=NFT+MARKET+BANNER"
                },
                {
                    link:"",
                    img:"https://via.placeholder.com/780x347?text=NFT+MARKET+BANNER"
                },
                {
                    link:"",
                    img:"https://via.placeholder.com/780x347?text=NFT+MARKET+BANNER"
                },
                {
                    link:"",
                    img:"https://via.placeholder.com/780x347?text=NFT+MARKET+BANNER"
                }
            ],
            catList:[
                {
                    img:'https://via.placeholder.com/352x352?text=NFT+MARKET+BANNER',
                    name:'Comming Soon (#239)',
                    price:147,
                    summon: '1/5'
                },
                {
                    img:'https://via.placeholder.com/352x352?text=NFT+MARKET+BANNER',
                    name:'Comming Soon (#240)',
                    price:147,
                    summon: '1/5'
                },
                {
                    img:'https://via.placeholder.com/352x352?text=NFT+MARKET+BANNER',
                    name:'Comming Soon (#241)',
                    price:147,
                    summon: '1/5'
                },
                {
                    img:'https://via.placeholder.com/352x352?text=NFT+MARKET+BANNER',
                    name:'Comming Soon (#241)',
                    price:147,
                    summon: '1/5'
                }
            ],
            games:[
                {
                    img:'https://via.placeholder.com/400x238?text=NFT+MARKET+BANNER',
                },
                {
                    img:'https://via.placeholder.com/400x238?text=NFT+MARKET+BANNER',
                },
                {
                    img:'https://via.placeholder.com/400x238?text=NFT+MARKET+BANNER',
                }
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
    .wrap{
        position: absolute;
        width: 100%;
        top: 68px;
        left: 0px;
    }
    .btn-main{
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;
    }

    .crumbs-detail{
      color: #fff;
      margin: 22px 0 10px;
    }

    .top-wrapper{
        width: 100%;
        padding: 0 0 60px;
        min-height: 531px;
        margin: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        background-color: rgba(0,0,0,.8);
        display: flex;
        justify-content: center;    
        // position: absolute;
        // left: 0px;
        // top: 68px;

        .top-box{
            display: flex;
            justify-content: space-between;
        }

        .left-view{
            width: 65%;
        }
        .right-view{
            width: 32%;
            padding: 40px 30px;
            box-sizing: border-box;
            border-radius: 16px;
            background-color: rgba(0,0,0,.33);
            color: #fff;

            .game-title{
                font-size: 30px;
                font-weight: 600;
            }

            .line{
                width: 100%;
                height: 1px;
                margin-top: 28px;
                margin-bottom: 30px;
                background: hsla(0,0%,100%,.1);
            }

            .game-info{
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 18px 0;
                color: #fff;

                .icon-list{
                    a{
                        margin-left: 10px;
                        img{
                            width: 20px;
                        }
                    }
                }
            }

            .item-buttons{
                margin-top: 40px;
                font-size: 16px;
                display: flex;
                justify-content: space-between;

                a{
                    width: 100%;
                    margin-right: 20px;

                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }


        img{
            width: 100%;
        }
    }

    .pc-inner-widget {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        text-align: left;
    }

    .section{

        .bg_img{
            padding-bottom: 59.6875%;
            background-repeat: no-repeat;
            background-size: cover;
            height: 0;
        }

    }
    .buy_list{
        li{
            width: calc(25% - 0.75em);
            border-radius: 16px;
        }

        .img_box{
            background-color: #f4f4f4;
        }
    }
    .game_list{
        li{
            width: 33.333%;
            flex-shrink: 0;
        }
    }
    
@media screen and (max-width: 1519px){
.pc-inner-widget {
    width: 1081px;
}
}
@media screen and (max-width: 1439px){
    .pc-inner-widget {
        width: 960px;
    }
    .gamepad-info-page .top-wrapper .top-box .right-view .game-title{
        font-size: 24px;
    }
    .gamepad-info-page .top-wrapper .top-box .right-view .game-info{
        font-size: 15px;
        margin: 14px 0;
    }
}
@media screen and (max-width: 1279px){
    .top-box{
        flex-wrap: wrap;
    }
    .pc-inner-widget {
        width: calc(100% - 60px);
    }
    .top-wrapper .left-view{
        width: 100%;
    }
    .top-wrapper .right-view{
        width: 100%;
        margin-top: 30px;
    }
}
@media screen and (max-width: 960px){
    .pc-inner-widget {
        width: 100%;
    }
}
@media screen and (max-width:768px) {
    .top-wrapper{
        padding: 60px 30px;
    }
    
    .buy_list{
        flex-wrap: wrap;
        justify-content: space-around;
        li{
            padding: 10px !important;
            width: calc(50% - 0.5em);
        }
        .img_box{
            font-size: 14px;
            +div{
                >div{
                    font-size: 12px;
                }
            }
        }
    }

    .game_list{
        flex-wrap: wrap;
        li{
            width: 100%;
        }
    }
}

</style>