<template>
    <div class="container">
        <ul class="fw-bold rounded-pill select-type d-flex mb-5 overflow-auto">
            <li v-for="(item,index) in poolList" :key="index" class="py-2 rounded-pill touchable" :class="{'bg-primary text-white':active==index}" @click="selectFn(item,index)">{{item.name}}</li>
        </ul>
        <small>Mining PooL Amount (TOKENS) ≈ $18,786,904</small>
        <h1 class="text-main fw-bold">{{activated_list.amount}}</h1>
        <div class="d-flex my-5 mining-box bg-white shadow rounded-lg">
            <div class="left-box rounded-lg p-3">
                <h5 class="fw-bold mb-3">{{activated_list.name}}</h5>
                <h3 class="fw-bold text-main mb-3">{{activated_list.remain}}</h3>
                <small class="mb-4">현재 기간 남은 상금(COIN) ≈ ${{activated_list.remain_dollar}}</small>
                <div class="detail-info">
                    <div class="d-flex align-items-center p-3 bg-cloudy rounded-lg mb-3 text-start">
                        <div class="d-flex flex-column w-50">
                            <h5 class="fw-bold">{{activated_list.total_m_power}}</h5>
                            <p>Total Mining Power</p>
                        </div>
                        <div class="d-flex flex-column">
                            <h5 class="fw-bold">{{activated_list.my_m_power}}</h5>
                            <p>My Mining Power</p>
                        </div>
                    </div>
                    
                    <div class="d-flex align-items-center p-3 bg-cloudy rounded-lg mb-3 text-start">
                        <div class="d-flex flex-column w-50">
                            <h5 class="fw-bold">{{activated_list.total_mortgage}}</h5>
                            <p>총 저당 수</p>
                        </div>
                        <div class="d-flex flex-column">
                            <h5 class="fw-bold">{{activated_list.my_mortgage}}</h5>
                            <p>내 저당 수</p>
                        </div>
                    </div>
                    
                    
                    <div class="d-flex p-3 bg-cloudy rounded-lg mb-3 text-start">
                        <div class="d-flex flex-column">
                            <h5 class="fw-bold">{{activated_list.total_staked_tokens}}</h5>
                            <p>Total Staked Tokens(tokens) ≈ $9,436,367</p>
                        </div>
                    </div>
                    
                    <div class="d-flex p-3 bg-cloudy rounded-lg mb-3 text-start">
                        <div class="d-flex flex-column">
                            <h5 class="fw-bold">{{activated_list.my_staked_tokens}}</h5>
                            <p>My Staked Tokens(tokens) ≈ $0</p>
                        </div>
                    </div>
                    
                    <div class="d-flex p-3 bg-cloudy rounded-lg mb-3 text-start">
                        <div class="d-flex flex-column">
                            <h5 class="fw-bold">{{activated_list.computing_power}}</h5>
                            <p>token을 얻기 위한 1000 컴퓨팅 파워 / 24H ≈ $3.8901</p>
                        </div>
                    </div>
                    
                    <div class="d-flex p-3 bg-cloudy rounded-lg mb-3 text-start">
                        <div class="d-flex flex-column w-100">
                            <h5 class="align-items-center fw-bold d-flex justify-content-between"><span>{{activated_list.earn}}</span> <button class="text-sm bg-primary border-0 text-white rounded-pill btn-sm">수취</button></h5>
                            <p>Token ≈ $0</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <ul class="list-4-gap list-2-mobile">
                    <li class="list-4 d-flex flex-column p-3" v-for="(item,index) in poolList[active].mortgage" :key="index">
                        <div class="bg-pastel position-relative rounded-lg d-flex flex-column justify-content-around mb-3 py-5 text-center">
                            <span class="position-absolute status-badge">컴퓨팅 파워 : {{item.computing_power}}</span>
                            <i class="far fa-plus-square fs-1 my-3"></i>
                            <span class="status">{{item.status}}</span>
                        </div>
                        <b-button class="bg-primary text-white rounded-pill mb-2">저당</b-button>
                        <b-button class="btn-outline-main rounded-pill">Update<br>HashRate</b-button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created(){
        this.activated_list=this.poolList[0]
    },
    data(){
        return{
            active:0,
            activated_list:null,
            poolList:[
                {
                    amount:44222962374938,
                    name:'Mining Pool 3.0',
                    remain:3700912426986,
                    remain_dollar:1571774,
                    total_m_power:5776550,
                    my_m_power:0,
                    total_mortgage:10272,
                    my_mortgage:0,
                    total_staked_tokens:22212500000000,
                    my_staked_tokens:0,
                    computing_power:9157054,
                    earn:0,
                    mortgage:[
                        {
                            status:'잠금 해제',
                            computing_power:1
                        },
                        {
                            status:'잠금 해제',
                            computing_power:1
                        },
                        {
                            status:'잠금 해제',
                            computing_power:1
                        },
                        {
                            status:'잠금 해제',
                            computing_power:1
                        },
                        {
                            status:'잠금 해제',
                            computing_power:1
                        },
                        {
                            status:'잠금 해제',
                            computing_power:1
                        },
                        {
                            status:'잠금 해제',
                            computing_power:1
                        },
                        {
                            status:'잠금 해제',
                            computing_power:1
                        },
                    ]
                },
                {
                    amount:44222962374938,
                    name:'Mining Pool 2.0',
                    remain:3700912426986,
                },
                {
                    amount:44222962374938,
                    name:'Mining Pool',
                    remain:3700912426986,
                },
                {
                    amount:44222962374938,
                    name:'ETC Pool',
                    remain:3700912426986,
                },
            ]
        }
    },
    methods:{
        selectFn(item,index){
            this.active=index;
            this.activated_list=item;
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>

<style lang="scss" scoped>
.select-type{
    max-width: initial;
    width: fit-content;
    >li{
        width: 150px;
        height: 44px;
    }
}
.mining-box{
    padding: 25px;
    .left-box{
        width: 30%;
        display: flex;
        flex-direction: column;
        padding-right: 35px !important;
        .detail-info p{
            font-size: 12px;
        }
    }
    .right-box{
        width: 70%;
        border-left: 1px solid #eee;
        padding-left: 25px !important;
        li>div{
            cursor: pointer;
        }
        button{
            height: 45px;
            line-height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            padding-bottom: 3px;
        }
        .status-badge{
            background-color: rgba(255, 255, 255, 0.5);   
            font-size: 13px;
            line-height: 2em;
        }
    }
}
@media screen and (max-width: 1439px){
    .right-box{
        .list-4-gap{
            justify-content: space-between;
            gap: 5px;
        }
        .list-4{
            width: 30%;
            padding: 0 !important;
        }
    }
}

@media screen and (max-width: 1279px){
    .mining-box{
        .left-box{
            width: 45%;
        }
        .right-box{
            width: 55%;
            .list-4-gap{
                justify-content: space-between;
            }
            .list-4{
                width: 48%;
                padding: 0 !important;
            }
        }

    }
}

@media screen and (max-width: 960px){
    
    .select-type{
        max-width: initial;
        width: 100%;
        >li{
            flex-shrink: 0;
            padding-bottom: 10px !important;
        }
    }
    .mining-box{
        flex-wrap: wrap;
        padding: 25px;
        .left-box{
            width: 100%;
            padding: 0 !important;
        }
        
        .right-box{
            width: 100%;
            padding-left: 0 !important;
            .list-4{
                padding: 0 !important;
            }
            
            button{
                height: 35px;
                font-size: 12px;
            }
        }
    }
}
@media screen and (max-width:768px){
}
</style>