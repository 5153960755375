<template>
    <div class="container">
        <div class="common-cover"></div>

        <div class="crumb-view crumbs-detail text-start">
            <div class="crumb-item">
                <router-link to="/main">홈</router-link><span class="crumb-space">&gt;</span>
            </div>
            <div class="crumb-item">
                <span>Games</span>
            </div>
        </div>

        <div class="game-wrapper">
            <div class="game-list">
                <div class="game-item" v-for="(item,index) in list" :key="index">
                    <router-link to="/game/dnaxcat" class="item-left">
                        <div class="left-inner" :style="`background-image: url('${item.img}');`"></div>
                    </router-link>
                    <div class="item-right">
                        <div class="game-name"><span class="name">{{item.name}}</span><span class="bsc"></span></div>
                        <div class="game-info"><span class="info-left">State</span><span class="info-right">{{item.state}}</span></div>
                        <div class="game-info"><span class="info-left">Platform</span><span class="info-right">{{item.platform}}</span></div>
                        <div class="game-info"><span class="info-left">Language</span><span class="info-right">{{item.language}}</span></div>
                        <div class="game-info">
                            <span class="info-left me-1">Category</span>
                            <div class="category info-right py-2">
                                <span class="category-item" v-for="(cate, cate_index) in item.category" :key="cate_index">{{cate}}</span>
                            </div>
                        </div>
                        <div class="item-buttons btn btn-main">
                            <router-link to="/game/dnaxcat">View Details</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            list:[
                {
                    img: require('@/assets/img/banner.jpg'),
                    name: 'DNAxCAT',
                    state: 'Game Online',
                    platform: 'Android',
                    language: 'English',
                    category: ['Collection', 'Education']
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .game-wrapper{
        .game-list{
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            .game-item{
                display: flex;
                background: #fff;
                box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
                border-radius: 16px;
                overflow: hidden;
                margin-bottom: 36px;

                a{
                    width: 66%;
                    border-radius: 16px;
                    position: relative;
                    overflow: hidden;
                }

                .item-left .left-inner{
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-size: cover;
                    background-color: #1b1b1b;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    transition: transform .6s ease;
                }
                .item-left:hover .left-inner{
                    transform: scale(1.03);
                }

                .item-right{
                    flex: 1;
                    padding: 40px;
                    box-sizing: border-box;

                    .game-name{
                        margin-bottom: 30px;
                        display: flex;
                        justify-content: space-between;

                        .name{
                            font-size: 30px;
                            font-weight: 600;
                        }
                        .bsc{
                            width: 75px;
                            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAA0CAMAAABfCzE1AAAAk1BMVEUAAAD1vAv1vAz1uwz/wA///2D1vQz/zjH/zBr1uwv1vAz1vAv/yR/0vAz2vQz/wRT/wA30vAz1vAv3vQz2vg31vAv0vAz2vAv5vgz1vAv2vAv0uwz0vAz2vQ71vAv1vAz0vA33vQz1vAv1vAz0vAz2vAv1vAv1vg71uwz2vAv1uwv2vAv4vg71vQ32whL3vgz0uwte/smDAAAAMHRSTlMA/ZWAEAJ/BQrLmvQIvVMMFNfPQTe2kFwr5Ybr8S+eg3ZFyKumofg0xGzeiiROHDzcobghAAADM0lEQVRYw93Z6XKqMBgG4AREKqsoixS07talp+/9X92Bj2Cs7THFM2Mzff+IYXCeid+ELKzOJI1caBE3SiesiW1waBRu2KTywZ04ZFokjB0Ov3YZyMdMo4xzGFVdca6VqnJxPmEpHKZZHKQsQsw0S4yIudCk2mVCuAxg6vRN9sgA32L1gce5JEutepxLstSqx7kkS616oEuylKpHuiRLpXq0S7LUqvtdw10Q7IbXjYtgGt7NMgXHMNCkz/6ZJ96mHK1j0WgFS1BGgcXaZEnetL0pWCoVY2rXEy6ztsSk6RzfZpTeRdtyqGApVCqXYD3XOa4PAF5YlRVQGtki2A/an2FhfTma7bN5zXv1urMcqZKuV/sGyxJ/3QvgVtcnoBBz4DkAuqxu8bl4JReA0Z3FnFYlXa8hU7AoJSEyyFmTD+zrWneBgInsOFyvG8sjl1D1euQSKkvNGgExddHmXOdFMas+UsCXzySVsRPLHA3J1agGg8ZFKntlqli2C149EQPl1dQ3By5a/ozHpy4sE2hcjQpoXB6pAFNRW8emm+wI4Jv3i971OLZ3DKdSJVxCJVxCJV1XrJc6x80BSD3qjQhVCmfRyibAoTtLqsgVtirpsioVuW6PW4ao5GE/B8mMKX1/A/z/ZSX2Zxab3WJxCqqU51J+TxtZUj8f3MuSrsT76k+0yGXeqq3e9LgFFvLW7vkgimIKRN1Z0iVU9seSD8mlKHkxei7ZZTIXWFU/Bbh3sKSLVF7ycYAY9chlMhVrCGyt0HHkID4Gyup2AUzlM4ui2HRiMZtU18Np5aKoWPYW6LH8wiC+rIG1fGYFBN1YpLp++QiXmhUDORnSM7SkEfbEwXfnzuIova4sK/nqVT3yVCwxXiX0TuSZUG1Esc8ANxOqEph3f1X3v5rYmIqJTRXH54AbM+ouLPtZsDcKgAdUdcu6zdhnfR+Aw7qzpEupEiyZLRm8JWREx9gp2vCNrWApXFL1DZYb+fOhMGQDwZxNWJsgyQnl735yiTF9y8Yn7/MS4+nXLch0Xb7qutjXdWtE140kXbfdfmCTUtctXU03wDU9LtD0cEXToyhdD+40PebU8lD4LzKaHkKY/GRRAAAAAElFTkSuQmCC);
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: 50%;
                        }
                    }
                    .game-info{
                        font-size: 16px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 14px 0;
                        color: #505f79;

                        .category{
                            .category-item{
                                padding: 6px 10px 6px;
                                border: 1px solid #541792;
                                color: #541792;
                                border-radius: 6px;
                                margin-right: 10px;

                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .item-buttons{
                        margin-top: 32px;
                        width: 100%;
                        border-radius: 30px;
                        padding: 12px 0;

                        a{
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
    }
    
@media screen and (max-width:768px) {
    .game-wrapper{
        .game-list{
            .game-item{
                flex-wrap: wrap;
                a{
                    width: 100%;
                }
                .item-left{
                    height: 0;
                    padding-bottom: 50%;
                }
                .item-right{
                    padding: 20px;
                    width:100%;
                    .game-name{
                        .name{
                            font-size: 24px;
                        }
                    }
                    .game-info{
                        font-size: 14px;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }   
}
</style>