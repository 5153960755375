<template>
    <div class="container">
        <div class="text-center mb-5">
            <h1 class="fw-bold mb-3">NFT Minting</h1>
        </div>
        <ul class="fw-bold rounded-pill select-type d-flex mb-5">
            <li class="py-2 rounded-pill" :class="{'bg-primary text-white':active==0}" @click="active=0">업그레이드 주조</li>
            <li class="py-2 rounded-pill" :class="{'bg-primary text-white':active==1}" @click="active=1">능량주조</li>
        </ul>
        <div class="d-flex my-5 mining-box bg-white shadow rounded-lg">
            <div class="left-box rounded-lg p-3">
                <div class="detail-info">
                    <div class="d-flex p-3 bg-cloudy rounded-lg mb-3 text-start">
                        <div class="d-flex flex-column w-100">
                            <img :src="nft_symbol" alt="" width="80" height="80" class="img-symbol rounded-circle">
                            <h5 class="align-items-center fw-bold d-flex justify-content-between mb-3">
                                <span>{{nft_name}}</span>
                                <button class="text-sm btn-outline-main rounded-pill btn-sm">세부사항</button>
                            </h5>
                            <div class="d-flex justify-content-between align-items-center mb-2 text-sm">
                                <span class="text-pale">주조된 NFT</span>
                                <span class="fw-bold">{{casted_nft}}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center text-sm">
                                <span class="text-pale">주조 한도</span>
                                <span class="fw-bold">{{casting_limit}}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="d-flex p-3 bg-cloudy rounded-lg mb-3 justify-content-center">
                        <div class="d-flex flex-column">
                            <h5 class="fw-bold">{{success_rate}}%</h5>
                            <p>주조성공률</p>
                        </div>
                    </div>
                    <button class="rounded-pill btn-main border-0 w-100 py-3 mb-2">즉시 주조</button>
                    <span class="text-center">
                        지불 필요 : <b class="text-main fw-bold">{{required_token}}</b> TOKEN
                    </span>

                    <p class="text-start py-4">
                        <b>주조규칙</b>
                        <br>
                        1. 4종은 NFT를 주조원료로 사용 가능하며, 서로 다른 NFT는 성공 확률이 다르다. 구체적으로 다음과 같다. Miner 7%，Sushi Master 10%，Army 12%，Spaceman 20%<br>
2. 예: 주조트로프내에 NFT4개를 채워면 주조성공 확률은 다음과 같다 : 7% * 4 = 28%<br>
3. 주조에 성공하면, 사용자는 NFT를 획득할 수 있으며, 주조 트로프 내에 모든 NFT는 파괴한다.<br>
4. 만약 주조 실패 시, 주조 트로프 내 랜덤으로 1개 NFT를 선택하여 파괴한다.
                    </p>
                </div>
            </div>
            <div class="right-box">
                <ul class="list-3-gap">
                    <li class="list-3 d-flex flex-column p-3" v-for="(item,index) in casting_nft" :key="index">
                        <div class="bg-pastel position-relative rounded-lg d-flex flex-column justify-content-around mb-3 py-5">
                            <i class="far fa-plus-square fs-1 my-3"></i>
                            <span class="status">NFT를 선택하세요.</span>
                        </div>
                        <b-button class="bg-primary text-white rounded-pill">NFT 추가</b-button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            active:0,
            nft_symbol:'https://via.placeholder.com/228x228?text=logo',
            nft_name:'NFT NAME',
            success_rate:0,
            casting_nft:[{},{},{},{}],
            casted_nft:20,
            casting_limit:20,
            required_token:150000000
        }
    },
    methods:{
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>

<style lang="scss" scoped>
.select-type{
    max-width: initial;
    width: fit-content;
    >li{
        width: 150px;
    }
}
.mining-box{
    padding: 25px;
    .left-box{
        width: 30%;
        display: flex;
        flex-direction: column;
        padding-right: 35px !important;
        .detail-info p{
            font-size: 12px;
        }
    }
    .right-box{
        width: 70%;
        border-left: 1px solid #eee;
        padding-left: 25px !important;
        li>div{
            cursor: pointer;
            height: 250px;
        }
        button{
            height: 45px;
            line-height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            padding-bottom: 3px;
        }
        .status-badge{
            background-color: rgba(255, 255, 255, 0.5);   
            font-size: 13px;
            line-height: 2em;
        }
    }
}
.img-symbol{
    object-fit: cover;
    margin: 0 auto 10px;
}
@media screen and (max-width:768px) {
    .mining-box{
        padding: 25px;
        flex-wrap: wrap;

        .left-box{
            width: 100%;
            padding-right: 0px !important;
        }
        .right-box{
            width: 100%;
            border-left: none;
            padding-left: 0px !important;
        }
    }
}
</style>