<template>
    <div class="container">
        <section class="section">
            <div class="text-start">
                <h1 class="fw-bold mb-3">LaunchPad</h1>
                <p class="mb-4">LaunchPad는 게임 개발자들에게 NFT, 팜스, NFT 경매, 거래 시장 등의 형태로 게임 내 고유 자산을 판매하는 등 일련의 게임 사전 출시 지원을 제공할 예정이다.</p>
            </div>
            <div class="info-box d-flex w-100 bg-primary p-4 rounded-pill">
                <div class="d-flex flex-column">
                    <h3 class="text-white">$ {{numberWithCommas(capitalization)}}</h3>
                    <small class="text-white">인큐베이팅 시가총액</small>
                </div>
                
                <div class="d-flex flex-column">
                    <h3 class="text-white">{{numberWithCommas(volume)}}</h3>
                    <small class="text-white">판매량</small>
                </div>
                
                <div class="d-flex flex-column">
                    <h3 class="text-white">{{numberWithCommas(users)}}</h3>
                    <small class="text-white">총 사용자</small>
                </div>
            </div>
        </section>
        <section>
            <h2 class="fw-bold mb-3">Completed Sales</h2>
            <ul class="d-flex flex-wrap pt-5 list-3-gap">
                <li v-for="(item,index) in launchpadList" :key="index" class="shadow d-flex flex-column p-3 list-3 rounded-lg">
                    <div class="d-flex flex-column position-relative">
                        <img :src="item.img" alt="" class="rounded">
                        <h5 class="py-4 fw-bold">{{item.name}}</h5>
                        <span class="position-absolute status-badge btn-main">{{item.status}}</span>
                    </div>
                    <div class="d-flex flex-column justify-content-around">
                        <b-button class="bg-primary border-0 py-2" pill @click="$router.push(`/launchpad/${item.name}`)"><span>자세히 보기</span></b-button>
                        <div class="d-flex community-group-icon py-2 fs-3 justify-content-center">
                            <a href="" target="_blank">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="" target="_blank"><i class="fab fa-telegram"></i></a>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {
    data(){
        return{
            capitalization:1692024879,
            volume:7832,
            users:416883,
            launchpadList:[
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#239)',
                    price:147,
                    status:"끝",
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#240)',
                    status:"끝",
                    price:147,
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#241)',
                    status:"끝",
                    price:147,
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#239)',
                    status:"끝",
                    price:147,
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#240)',
                    status:"끝",
                    price:147,
                },
                {
                    img: require('@/assets/img/banner2.jpg'),
                    name:'Comming Soon (#241)',
                    status:"끝",
                    price:147,
                }
            ]
        }
    },
    methods:{
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>

<style lang="scss" scoped>
section{
    margin-bottom: 100px;
}
</style>